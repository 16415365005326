import React, { useState } from "react";
import { Grid, Typography, Modal, Box } from "@mui/material";
import Image from "next/image";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import CloseIcon from "@mui/icons-material/Close";

import ApostropheImg from "../../../public/assets/images/home/ApostropheImg.webp";
import ApostropheImgInverted from "../../../public/assets/images/home/ApostropheImgInverted.webp";
import youtubeIcon from "../../../public/assets/images/home/youtubeIcon_1.webp";

import Testimonial1 from "../../../public/assets/images/home/Ash-Mohammad_600X300.webp";
import Testimonial2 from "../../../public/assets/images/home/Testimonial2.webp";
import Testimonial3 from "../../../public/assets/images/home/Testimonial-Ashutosh-Pandey.webp";
import Testimonial4 from "../../../public/assets/images/home/Pawan-Sharma_600X300.webp";
import Testimonial5 from "../../../public/assets/images/home/Swaroop-Biswas-600X300.webp";
import Testimonial6 from "../../../public/assets/images/home/Thumbnail_Rahul_Roy-1.webp";
import Testimonial7 from "../../../public/assets/images/home/Thumbnail_Aakash_Garg.webp";
import Testimonial8 from "../../../public/assets/images/home/testimonial-1.webp";
import Testimonial9 from "../../../public/assets/images/home/Testtimonial_Thumbnail_Prakash.webp";
import Testimonial10 from "../../../public/assets/images/home/Thumbnail_Sahil_Arora.webp";
import Testimonial11 from "../../../public/assets/images/home/Thumbnail_Krishna_Adhikari.webp";

import Sanjay from "../../../public/assets/images/home/testimonial-2.webp";

const testimonials = [
  Testimonial1,
  Testimonial2,
  Testimonial3,
  Testimonial4,
  Testimonial5,
  Testimonial6,
  Testimonial7,
  Testimonial8,
  Testimonial9,
  Testimonial10,
  Testimonial11,
];

const videoTestimonial = [
  "https://www.youtube.com/embed/D52nUn_m2SI?autoplay=1",
  "https://www.youtube.com/embed/c5xWuPTsRho?autoplay=1",
  "https://www.youtube.com/embed/2DtQPzOacQ4?autoplay=1",
  "https://www.youtube.com/embed/0Vn4xmTze7k?autoplay=1",
  "https://www.youtube.com/embed/E9XHIEHKDhQ?autoplay=1",
  "https://www.youtube.com/embed/Xz9FHEjsIQU?autoplay=1",
  "https://www.youtube.com/embed/cwbmdlRjhCI",
  "https://www.youtube.com/embed/sbDZpNDGUCA",
  "https://www.youtube.com/embed/YVJJUwFIBoc",
  "https://www.youtube.com/embed/8xIscsgWN0c?autoplay=1",
  "https://www.youtube.com/embed/AEycpf3OBqU?autoplay=1",
];

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 2,
    slidesToSlide: 2,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
    slidesToSlide: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    slidesToSlide: 1,
  },
};

function Testimonials() {
  const [showVideo, setShowVideo] = useState(false);
  const [videoId, setVideoId] = useState(0);

  const playFunction = (videoId: any) => {
    setShowVideo(true);
    setVideoId(videoId);
  };

  const pauseFunction = () => {
    setShowVideo(false);
  };

  return (
    <Grid
      container
      sx={{
        minHeight: { xs: "40vh", md: "70vh" },
        padding: { xs: "40px 0 20px 0", md: "80px 0" },
        width: "100%",
        backgroundColor: "#F4F7FF",
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        position: "relative",
      }}
    >
      <Typography
   
        sx={{
          fontSize: { xs: "24px", md: "40px" },
          textAlign: "left",
          width: "80%",
          color: "#0A0C0F",
          position: "relative",
          zIndex: 2,
          fontWeight: "700",
        }}
      >
        Reviews from our Partners
        <Image
          src={ApostropheImgInverted}
          alt="apostropheImg"
          style={{
            position: "absolute",
            left: "-8px",
            zIndex: -1,
            bottom: "25px",
            width: "auto",
          }}
          id="ApostropheImgInverted"
          loading="lazy"
        />
      </Typography>

      <Grid
        sx={{
          margin: { xs: "20px 0", md: "50px 0" },
          width: "80%",
          maxWidth: "1440px",
        }}
      >
        <Carousel
          autoPlay={!showVideo}
          responsive={responsive}
          // showDots={true}
          containerClass="react-multi-carousel-list"
          itemClass="react-multi-carousel-item"
          infinite={true}
          swipeable={true}
        >
          {testimonials.map((testimonial, index) => (
            <div key={index} style={{ position: "relative" }}>
              <Image
                src={testimonial}
                alt={`testimonial-${index + 1}`}
                style={{
                  width: "545px",
                  objectFit: "contain",
                  height: "360px",
                }}
                onClick={() => playFunction(index)}
                loading="lazy"
              />
              <div
                style={{
                  width: "100%",
                  position: "absolute",
                  top: "32%",
                  right: "0",
                  left: "0",
                }}
              >
                <div style={{ width: "12%", margin: "0 auto" }}>
                  <Image
                    src={youtubeIcon}
                    alt="youtubeIcon"
                    style={{
                      width: "120px",
                      objectFit: "contain",
                      height: "120px",
                    }}
                    onClick={() => playFunction(index)}
                    loading="lazy"
                  />
                </div>
              </div>
            </div>
          ))}
          <Grid display={"flex"} justifyContent={"center"}>
            <Box
              sx={{
                height: "350px",
                width: { xs: "100%", md: "90%" },
                display: "grid",
                gridTemplateRows: "3fr 1fr",
                boxSizing: "border-box",
                borderRadius: "10px",
                overflow: "hidden",
                background: "white",
                zIndex: 10,
              }}
              className="testiCards"
            >
              <Box
                sx={{
                  padding: { xs: "10px", md: "25px" },
                  boxSizing: "border-box",
                  position: "relative",
                  overflow: "hidden",
                }}
              >
                <Typography
                  sx={{
                    fontWeight: 600,
                    fontSize: { xs: "16px", md: "24px" },
                    zIndex: 3,
                    paddingBottom: { xs: "10px", md: "15px" },
                    display: "block",
                  }}
                  className="testiHeading"
                >
                  I earned about ₹43,000 with GroMo in last 2 months.
                </Typography>
                <Typography
                  sx={{
                    fontWeight: "400",
                    fontSize: { xs: "15px", md: "20px" },
                    lineHeight: { xs: "", md: "32px" },
                  }}
                >
                  I have been working with GroMo for some time now and really
                  like how easy it is to earn with it. I am now getting more
                  bank and saving accounts sold and getting my payout on time.
                </Typography>
                <Image
                  src={ApostropheImg}
                  alt="apostrophe"
                  style={{
                    position: "absolute",
                    right: "2%",
                    top: "3%",
                    height: "30px",
                    width: "30px",
                    zIndex: -1,
                  }}
                  loading="lazy"
                />
              </Box>
              <Box
                sx={{
                  boxSizing: "border-box",
                  display: "grid",
                  gridTemplateColumns: {
                    xs: "2.5fr 7.5fr",
                    md: "1.5fr 8.5fr",
                  },
                  background: "#FFFFFF",
                }}
              >
                <Box
                  sx={{
                    display: "grid",
                    placeContent: "center",
                    padding: { xs: "0", md: "0" },
                  }}
                >
                  <Image
                    src={Testimonial8}
                    alt="GroMoPattern"
                    style={{
                      height:
                        globalThis.window?.screen.availWidth < 500
                          ? "30px"
                          : "50px",
                      width:
                        globalThis.window?.screen.availWidth < 500
                          ? "30px"
                          : "50px",
                      borderRadius: "50%",
                    }}
                    loading="lazy"
                  />
                </Box>
                <Box
                  sx={{
                    display: "grid",
                    placeContent: "center",
                    paddingLeft: { xs: "10px", md: "15px" },
                  }}
                >
                  <Box>
                    <Typography
                      sx={{
                        fontSize: { xs: "13px", md: "20px" },
                        fontWeight: "600",
                      }}
                    >
                      Sanjay
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: { xs: "13px", md: "18px" },
                        fontWeight: "400",
                        color: "#5B6871",
                      }}
                    >
                      Uber Driver
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Grid>
        </Carousel>
      </Grid>
      <Modal open={showVideo} onClose={pauseFunction}>
        <Box
          sx={{
            position: "absolute",
            top: "10%",
            left: { xs: "2%", md: "10%" },
            width: { xs: "96%", md: "80%" },
            boxShadow: 24,
          }}
        >
          <Grid
            sx={{
              display: "grid",
              placeContent: "center",
              position: "relative",
            }}
          >
            <iframe
              width="100%"
              height={globalThis.window?.screen.availWidth < 500 ? 200 : 500}
              src={videoTestimonial[videoId]}
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
            <CloseIcon
              onClick={pauseFunction}
              sx={{
                position: "absolute",
                right: "0",
                top: "0",
                fontSize: "30px",
                color: "#FFF",
                cursor: "pointer",
              }}
            />
          </Grid>
        </Box>
      </Modal>
    </Grid>
  );
}

export default Testimonials;
